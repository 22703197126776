"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var ramda_1 = require("ramda");

var equalsAndAlways = ramda_1.useWith(ramda_1.unapply(ramda_1.identity), [ramda_1.equals, ramda_1.always]); // toggle :: a -> b -> (* -> *)

exports.toggle = ramda_1.compose(ramda_1.cond, ramda_1.juxt([equalsAndAlways, ramda_1.flip(equalsAndAlways), ramda_1.always([ramda_1.T, ramda_1.identity])]));