"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
/*
 * String utilities
 */

var ramda_1 = require("ramda");

var arrays_1 = require("./arrays");

exports.capitalise = function (s) {
  return s.slice(0, 1).toUpperCase() + s.slice(1);
};

exports.onlynl = function (ss) {
  for (var _len = arguments.length, placeholders = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
    placeholders[_key - 1] = arguments[_key];
  }

  return ss.reduce(function (acc, s, i) {
    return "".concat(acc).concat(placeholders[i - 1]).concat(s);
  }).replace(/[ \t][ \t]+/g, '');
};

exports.listify = function (xs) {
  var opts = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {
    and: true
  };

  if (!opts.and) {
    return ramda_1.intersperse(', ', xs);
  }

  switch (xs.length) {
    case 0:
    case 1:
      return xs;

    case 2:
      return ramda_1.intersperse(' and ', xs);

    case 3:
    default:
      return ramda_1.intersperse(', ', xs.slice(0, -1)).concat(' and ').concat(xs.slice(-1));
  }
};

exports.readableListify = function (xs, opts) {
  return exports.listify(xs, opts).join('');
};

exports.truncate = function (s, limit) {
  var placeholder = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '...';
  return arrays_1.truncate(s.split(''), limit, placeholder).join('');
};