"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.delay = function () {
  var s = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
  return new Promise(function (resolve) {
    return setTimeout(resolve, s);
  });
};