"use strict";

var _defineProperty = require("/tmp/build_c5bfeae9/dashboard-app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/defineProperty");

Object.defineProperty(exports, "__esModule", {
  value: true
});

var ramda_1 = require("ramda");

var getSorter = function getSorter(o) {
  switch (o) {
    case 'asc':
      return ramda_1.ascend;

    case 'desc':
    default:
      return ramda_1.descend;
  }
};

exports.sort = function (specs, data) {
  var s = specs.map(function (_ref) {
    var _ref$accessor = _ref.accessor,
        accessor = _ref$accessor === void 0 ? ramda_1.identity : _ref$accessor,
        order = _ref.order;
    var sorter = getSorter(order);
    return sorter(accessor);
  });
  return ramda_1.sortWith(s, data);
};

exports.innerJoin = function (as, bs, pred) {
  var x = [];

  for (var i = 0; i < as.length; i++) {
    var a = as[i];

    for (var j = 0; j < bs.length; j++) {
      var b = bs[j];

      if (pred(a, b)) {
        x.push(Object.assign(Object.assign({}, a), b));
      }
    }
  }

  return x;
};

exports.collectBy = function (fn, xs) {
  return xs.reduce(function (acc, x) {
    return ramda_1.assoc(fn(x), ramda_1.has(fn(x), acc) ? acc[fn(x)].concat(x) : [x], acc);
  }, {});
};

exports.truncate = function (xs, limit, placeholder) {
  return limit < xs.length ? xs.slice(0, limit).concat(placeholder) : xs;
};

exports.headOrId = function (xs) {
  return Array.isArray(xs) ? xs[0] : xs;
};

exports.interpolateObjFrom = function (xs, zero, obj) {
  return xs.reduce(function (acc, x) {
    return x in acc ? acc : Object.assign(Object.assign({}, acc), _defineProperty({}, x, zero));
  }, obj);
};