"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var fs = require("fs");

exports.sync = {
  read: function read(fpath) {
    return fs.readFileSync(fpath, 'utf8');
  },
  write: function write(fpath, content) {
    return fs.writeFileSync(fpath, content, {
      flag: 'wx'
    });
  }
};