"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var ramda_1 = require("ramda");

exports.roundTo = ramda_1.curry(function (p, n) {
  return Math.round(n * Math.pow(10, p)) / Math.pow(10, p);
});