"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
/*
 * Utilities for getting random quantities
 */

var moment = require("moment");

var string_1 = require("./string");

var randomWords = require('random-words');

exports.number = function (max) {
  var min = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
  return (max - min) * Math.random() + min;
};

exports.integer = function (max) {
  var min = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
  return Math.round(exports.number(max, min));
};

exports.element = function (xs) {
  return xs[exports.integer(xs.length)] || xs[0];
};

exports.date = function (from, to) {
  return new Date(exports.integer(from.valueOf(), to.valueOf()));
};

exports.dateThisMonth = function () {
  return exports.date(moment().startOf('month').toDate(), new Date());
};

exports.password = function () {
  return randomWords({
    min: 3,
    max: 4
  }).map(string_1.capitalise).concat(exports.integer(99)).concat(exports.element(['!', '@', '£', '$', '%', '&', '*', '?'])).join('');
};