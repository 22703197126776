"use strict";

var _slicedToArray = require("/tmp/build_c5bfeae9/dashboard-app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/slicedToArray");

Object.defineProperty(exports, "__esModule", {
  value: true
});

var ramda_1 = require("ramda");

var _1 = require(".");

var SEC_PER_MIN = 60;
var MIN_PER_HR = 60;
var HRS_PER_DAY = 24;
var HRS_PER_WORKING_DAY = 8;
var SEC_PER_HR = SEC_PER_MIN * MIN_PER_HR;
var SEC_PER_DAY = SEC_PER_HR * HRS_PER_DAY;

exports.toSeconds = function (d) {
  return Object.entries(d).reduce(function (acc, _ref) {
    var _ref2 = _slicedToArray(_ref, 2),
        k = _ref2[0],
        _ref2$ = _ref2[1],
        v = _ref2$ === void 0 ? 0 : _ref2$;

    switch (k) {
      case 'days':
        return acc + v * SEC_PER_DAY;

      case 'hours':
        return acc + v * SEC_PER_HR;

      case 'minutes':
        return acc + v * SEC_PER_MIN;

      case 'seconds':
        return acc + v;

      default:
        return acc;
    }
  }, 0);
};

exports.toWorkingDays = function (d) {
  return exports.toSeconds(d) / (SEC_PER_HR * HRS_PER_WORKING_DAY);
};

exports.toHours = function (d) {
  return exports.toSeconds(d) / SEC_PER_HR;
};

exports.fromSeconds = function (s) {
  if (s < 0) {
    throw new Error('negative seconds not supported');
  }

  var days = Math.floor(s / SEC_PER_DAY);
  var hours = Math.floor((s - days * SEC_PER_DAY) / SEC_PER_HR);
  var minutes = Math.floor((s - days * SEC_PER_DAY - hours * SEC_PER_HR) / SEC_PER_MIN);
  var seconds = Math.floor(s - days * SEC_PER_DAY - hours * SEC_PER_HR - minutes * SEC_PER_MIN);
  return ramda_1.filter(function (v) {
    return v > 0;
  }, {
    days: days,
    hours: hours,
    minutes: minutes,
    seconds: seconds
  });
};

exports.sum = function (d1, d2) {
  return exports.fromSeconds(exports.toSeconds(d1) + exports.toSeconds(d2));
};

exports.diff = function (d1, d2) {
  return exports.fromSeconds(exports.toSeconds(d1) - exports.toSeconds(d2));
};

exports.equals = function (d1, d2) {
  return exports.toSeconds(d1) === exports.toSeconds(d2);
};

exports.greaterThan = function (d1, d2) {
  return exports.toSeconds(d1) > exports.toSeconds(d2);
};

exports.accumulate = function (ds) {
  return ds.reduce(function (acc, d) {
    return _1.Duration.sum(acc, d);
  }, exports.fromSeconds(0));
};