export enum ColoursEnum {
  black = '#333333',
  white = '#FFFFFF',
  transparentWhite = 'rgb(255, 255, 255, 0.9)',
  lightGrey = '#F8F8F8',
  grey = '#D1D1D1',
  darkGrey = '#707070',
  orange = '#FFBF00',
  purple = '#8000FF',
  red = '#F44336',
  mustard = '#FFBF00',
}

export enum GraphColoursEnum {
  lightRed = '#E36860',
  lightPink = '#EF7CA3',
  lightViolet = '#9C78D9',
  lightPurple = '#6370BC',
  lightBlue = '#63c0cc',
  lightGreen = '#7caf7e',
  lightLime = '#D7E360',
  lightSunrise = '#FDAF3A',
  lightOrange = '#FC855F',
  lightBrown = '#8B7972',
  red = '#F44336',
  pink = '#E91E63',
  violet = '#9C27B0',
  purple = '#673AB7',
  navy = '#3F51B5',
  blue = '#2196F3',
  greenBlue = '#009688',
  green = '#4CAF50',
  lime = '#CDDC39',
  yellow = '#FFEB3B',
  sunrise = '#FFC107',
  orange = '#FF9800',
  sunset = '#FF5722',
  brown = '#795548',
}

export const GraphColourList = [
  GraphColoursEnum.lightRed,
  GraphColoursEnum.lightPink,
  GraphColoursEnum.lightViolet,
  GraphColoursEnum.lightPurple,
  GraphColoursEnum.lightBlue,
  GraphColoursEnum.lightLime,
  GraphColoursEnum.lightSunrise,
  GraphColoursEnum.lightOrange,
  GraphColoursEnum.lightBrown,
  GraphColoursEnum.red,
  GraphColoursEnum.pink,
  GraphColoursEnum.violet,
  GraphColoursEnum.purple,
  GraphColoursEnum.navy,
  GraphColoursEnum.blue,
  GraphColoursEnum.greenBlue,
  GraphColoursEnum.green,
  GraphColoursEnum.lime,
  GraphColoursEnum.sunrise,
  GraphColoursEnum.orange,
  GraphColoursEnum.sunset,
  GraphColoursEnum.brown,

];
