"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
/*
 * Utilities for objects
 */

var ramda_1 = require("ramda");

exports.reduceKeys = function (f, init, o) {
  return Object.keys(o).reduce(function (acc, key) {
    return f(acc, key);
  }, init);
};

exports.reduceValues = function (f, init, o) {
  return Object.keys(o).reduce(function (acc, key) {
    return f(acc, o[key]);
  }, init);
};

exports.mapKeys = function (f) {
  return function (o) {
    return Object.keys(o).reduce(function (acc, k) {
      return ramda_1.assoc(f(k), o[k], acc);
    }, {});
  };
};

exports.mapValues = function (f, o) {
  return Object.keys(o).reduce(function (acc, k) {
    return ramda_1.assoc(k, f(o[k]), acc);
  }, {});
};

exports.renameKeys = function (map) {
  return function (o) {
    return Object.keys(o).reduce(function (acc, k) {
      return ramda_1.assoc(map[k] || k, o[k], acc);
    }, {});
  };
};

exports.evolveKeys = function (map, o) {
  return Object.keys(o).reduce(function (acc, k) {
    return ramda_1.assoc(ramda_1.has(k, map) ? map[k](k) : k, o[k], acc);
  }, {});
};

exports.pickOrAll = function (xs, o) {
  return xs ? ramda_1.pick(xs, o) : Object.assign({}, o);
};